<template>
  <CriarFluxo />
</template>

<script>
import CriarFluxo from '@/components/Fluxo/CriarFluxo'
export default {
  components: {CriarFluxo},
  data(){
    return{}
  }
}
</script>

<style>

</style>