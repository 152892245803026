<template>
  <div class="container">
    <div class="header" v-if="fluxo.length === 0">
      <span>
        <h3>Criar um novo fluxo</h3>
        <p style="text-align:left">Preencha com atenção.</p>
      </span>
      <div class="grow"/>
      <button type="button" class="btn-voltar" @click="$router.go(-1)">voltar</button>
    </div>
    <form class="header form" v-if="fluxo.length > 0"  autocomplete="off" @submit="submeterform">
      <span>
        <v-text-field            
          v-model="nomefluxo"
          name="nomefluxo"
          label="Nome"
          dense
          autocomplete="off"
          solo
          clearable
          :error-messages="erros.nomefluxo"
          @mousedown="(e) => limparErrosVtext(e)"
        ></v-text-field>
      </span>
      <div class="grow"/>
      <button type="submit" class="btn-form">criar</button>
    </form>
    <div class="wrapper">
      <div class="fluxo">
        <ul>
          <li 
            v-for="etapa, idx in fluxo"
            :key="etapa.id" 
            @click="() => selecionarEtapa(etapa)">
            <span>
              <label>id</label>
              <p>{{etapa.id}}</p>
            </span>
            <span>
              <label>nome</label>
              <p>{{etapa.nome}}</p>
            </span>
            <div class="grow"/>
            <span class="dropdown-wrapper">
              <button type="button" @click.stop="() => selctionarDropdown(idx)"><v-icon>mdi-dots-vertical</v-icon></button>
              <span v-if="idx == currindex" class="dropdown">
                <button type="button" v-if="idx > 0 && idx < fluxo.length" @click.stop="() => abaixo(idx)"><v-icon>mdi-arrow-up-bold-circle-outline</v-icon></button>
                <button type="button" v-if="idx >= 0 && idx < (fluxo.length-1)" @click.stop="() => acima(idx)"><v-icon>mdi-arrow-down-bold-circle-outline</v-icon></button>
              </span>
            </span>
          </li>
        </ul>
      </div>
      <div class="etapas">
        <ul style="overflow:auto;">
           <li style="width:100%;"
            v-for="etapa in etapas"
            :key="etapa.id" 
            @click.prevent="() => selecionarEtapa(etapa)">
            <p>{{etapa.nome}}</p>
            <button type="button"><v-icon color="#f9f9f2" @click.stop="() => abrirModal(etapa)">mdi-information-outline</v-icon></button>
          </li>
        </ul>
        <span>
          <v-icon   v-if="!modaltodasetapas"  size="25" color="#1377f2" @click="() => ordenar()">mdi-refresh</v-icon>
          <v-icon v-if="!modaltodasetapas"  size="25" color="#1377f2" @click="()=>mostrarTodasEtapas()">mdi-expand-all-outline</v-icon>
          <v-icon v-if="modaltodasetapas" size="25" color="#F23E2C" @click="()=>mostrarTodasEtapas()">mdi-close-circle-outline</v-icon>
        </span> 
      </div>
    </div>
    <div class="modal-wrapper" v-if="abrirmodal">
      <div class="modal" >
        <div class="modal-header">
          <button type="button" class="btn-close"><v-icon size="28" color="#F23E2C" @click.stop="() => fecharModal()">mdi-close-circle-outline</v-icon></button>
        </div>
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field            
              v-model="nome"
              name="nome"
              label="Nome"
              outlined
              readonly
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="qtdedias"           
              name="qtdedias"
              label="Quantidade de dias"
              outlined
              readonly
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            class="d-flex"
            cols="12"
            sm="6"
          >
              <Dropdown
                :multiple="false"
                :options="gadgets"
                id="gadget-select"
                v-model="gadget"
                data-cy="gadget"
                :border="'1px solid rgb(62, 62, 62)'"
                :borderradius="'0.3rem'"
                :name="'gadget'"
                :placeholder="'Gadget'"
                :disabled="true"
                :hidedetails="true"
                :initialValue="gadget"
                />
          </v-col>
          <v-col
            class="d-flex"
            cols="12"
            sm="6"
          >
              <Dropdown
                :multiple="false"
                :options="destinos"
                id="destino-select"
                v-model="destino"
                data-cy="destino"
                :border="'1px solid rgb(62, 62, 62)'"
                :borderradius="'0.3rem'"
                :placeholder="'Destino'"
                :name="'destino'"
                :disabled="true"
                :hidedetails="true"
                :initialValue="destino"

              />
          </v-col>
          <v-col
            class="d-flex"
            cols="12"
            sm="12"
          >
              <Dropdown
                :multiple="false"
                :options="notificartipos"
                id="notificar-select"
                v-model="notificar"
                data-cy="notificar"
                :border="'1px solid rgb(62, 62, 62)'"
                :borderradius="'0.3rem'"
                :placeholder="'Notificar'"
                :name="'notificar'"
                :disabled="true"
                :hidedetails="true"
                :initialValue="notificar"
              />
          </v-col>
        </v-row>  
      </div>
    </div>
  </div>

</template>

<script>
import {alertar} from '@/services/notificacao.service.js'
import GadgetServico from '@/services/gadget.service.js'
import FluxoServico from '@/services/fluxo.service.js'
import EtapaServico from '@/services/etapa.service.js'
import Dropdown from '@/components/Dropdown.vue';
export default {
  components: {Dropdown},
  data(){
    return {
      reqloading: false,
      etapas: [],
      fluxo: [],
      curretapa: null,
      abrirmodal: false,
      gadgets: [],
      gadget: null,
      nome: null,
      qtdedias: null,
      destinos: [{nome: 'Economy', value: 1}, {nome: 'Cliente', value: 2}],
      destino: null,
      notificartipos: [{nome: 'Economy', value: 1}, {nome: 'Cliente', value: 2}, {nome: 'Todos', value: 3}],
      notificar:null,
      currindex: null,
      modaltodasetapas: false,
      nomefluxo: null,
      etapasdefault: [

      ],
      dataaux:[],
      erros: {
        nomefluxo: ''
      }
    }
  },
  methods: {
    limparErrosVtext(e){
      this.erros[e.target.getAttribute('name')] = null
    },
    selctionarDropdown(idx){
      this.currindex = idx
    },
    selecionarEtapa(etapa){
      if(!this.modaltodasetapas){
        const inFluxo = this.fluxo.filter(el => el.id === etapa.id)[0]
        if(inFluxo){
          const novoestado = this.fluxo.filter(el => el.id !== etapa.id)
          this.fluxo = novoestado
          this.etapas.push(etapa)
        }else{
          this.fluxo.push(etapa)
          const estadoatual = this.etapas.filter(el => el.id !== etapa.id)
          this.etapas = estadoatual
        }
      }
    },
    abrirModal(etapa){
      this.curretapa = etapa
      this.abrirmodal = true
      this.destino = etapa.destino
      this.gadget = etapa.gadget
      this.notificar = etapa.notificar
      this.nome = etapa.nome
      this.qtdedias = etapa.qtdedias
    },
    fecharModal(){
      this.curretapa = {}
      this.abrirmodal = false
    },
    async requisitarGadgets(){
      this.gadgets = await GadgetServico.buscar().then(res => {
        return res.data.map(gadget => ({value:gadget.id, nome:gadget.nome}))
      })
    },
    abaixo(idx){
      this.move(idx, -1)
      this.currindex = null
    },
    acima(idx){
      this.move(idx, 1)
      this.currindex = null
    },
    move(fromIndex, direction) {
      const array = this.fluxo
      const element = array[fromIndex];
      array.splice(fromIndex, 1);
      array.splice(fromIndex+direction, 0, element);
      this.fluxo = array
    },
    mostrarTodasEtapas(){
      if(this.modaltodasetapas){
        document.querySelector('.wrapper').classList.remove('todas-etapas')
        document.querySelector('.fluxo').style.display = 'unset'
        this.modaltodasetapas = false
        this.etapas = this.dataaux
      }else{
        document.querySelector('.wrapper').classList.add('todas-etapas')
        document.querySelector('.fluxo').style.display = 'none'
        this.modaltodasetapas = true
        this.dataaux = this.etapas
        this.etapas = this.etapasdefault
      }

    },
    ordenar() {
        const novoestado = this.etapas.sort((a, b) => (a.id > b.id) ? 1 : -1)
        this.etapas = novoestado
    },
    async requisitarEtapas(){
      const etapas = await EtapaServico.buscar().then(res=>{
        return res.data
        // return res.data.map(etapa => ({
        //   nome: etapa.nome,
        //   qtdedias: etapa.qtdedias,
        //   idgadget: etapa.idgadget,
        //   notificar: etapa.notificar,
        //   destino: etapa.destino,
        //   id: etapa.id,
        // })).filter(el=> {
        //   let obj = new Array()
        //   let validado;
        //   Object.keys(el).map(key => {
        //     if(el[key]==null){
        //       obj.push(false)
        //     }else{
        //       obj.push(true)
        //     }
        //   })
        //   obj.includes(false) ? validado = false : validado = true
        //   return validado
        // })
      })
      this.etapas = etapas
      this.etapasdefault = etapas
    },
    async submeterform(e){
      e.preventDefault()
      if(this.validacao()){
        const dadosfluxo = {
          ativo: true,
          nome: this.nomefluxo
        }
        await FluxoServico.criar(dadosfluxo)
            .then(res => {
              if(res.status === 200){
                //vincular etapas ao fluxo
                this.fluxo.map(async (et,idx) => {
                  let dadosvincular = {
                    idfluxo:res.data.inserted[0],
                    idetapa:et.id,
                    ativo:true,
                    ordemfluxo: idx
                  }
                    await EtapaServico.vincular(dadosvincular).then(res => {
                      if(res.status === 200){
                        console.log('vinculacao com sucesso')
                        alertar('moment', 'Etapa Vinculada com sucesso', '', 'success')
                      }
                    }).catch(e=>{
                      console.log('error vinculacao', e)
                      })
                })
                this.nomefluxo = null
                this.fluxo = []
                const etapas = this.etapasdefault 
                this.etapas = etapas
                alertar('success', '=)', 'Fluxo criado com sucesso!')
              }
            })
            .catch(e => {
              console.log('error em atualizar fluxo', e)
              alertar('warning', '=/', 'Falha na criação do fluxo!')
            })

      }
    },
    validacao(){
      let validado = true
      if(!this.nomefluxo || this.nomefluxo.trim() === ''){
        validado = false
        this.erros['nomefluxo'] = 'O campo nome é obrigatório.'
      }
      if(this.fluxo.length === 0){
        validado = false
        alertar('question', '=/', 'O fluxo está vazio. Selecione alguma etapa.')
      }
      return validado
    }
  },
  async mounted(){
    await this.requisitarGadgets()
    await this.requisitarEtapas()
  }
}
</script>

<style lang="scss" scoped>

.header{
    display: flex;
    padding: 1em;
    margin: 1em auto;
    background: rgba(215,211,222,0.5);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 10px;
    height: 12%;
    @media screen and (min-width:768px) {
      height: 15%;
    }
    &.form{
      >span{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
}
.container{
  height: calc(100vh - 56px);
  @media screen and (min-width:768px) {
    height: calc(100vh - 64px);
  }
}
.wrapper{
  height: 100%;
  display: grid;
  grid-template-rows: .7fr .3fr;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  grid-gap: 1em;
  height: 82%;
  border-radius: 20px;
  @media screen and (min-width: 375px) and (min-height: 812px){
    grid-template-rows: .8fr .2fr !important;
    grid-template-columns: 1fr !important;
  }
  @media screen and (min-width:768px) {
    height: 79%;
    display: grid;
    grid-template-columns: .7fr .3fr;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    grid-gap: 1em;
  }
  .fluxo{
    background-color: #f9f9f2;
    border-radius: 10px;
    padding: 1em;
    overflow: hidden;
    box-shadow: 4px 4px #d9d9d9;
    >ul{
      width: 100%;
      height: 100%;
      padding: .5em;
      overflow-y: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
      >li{
        user-select: none;
        &:hover{
          cursor: pointer;
        }
        width: 100%;
        height: 4rem;
        background-color: white;
        // background-color: rgba(19,119,242,0.1);
        box-shadow:  0 10px 30px #d9d9d9,
                0 -10px 30px #ffffff;
        border-radius: 10px;  
        margin: 0 .3em;  
        flex-shrink: 0;
        position: relative;
        padding: 1em;
        display: flex;
        justify-content: space-around;
        align-items: center;
        z-index: 100;
        margin-bottom: 1em;
        >span{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin: 0 .5em;
          width: fit-content;
          >label{
            font-weight: bold;
            letter-spacing: 0.02em;
            font-size: 12px;
            color: #aaa
          }
          >p{
            font-weight: bold;
            letter-spacing: 0.02em;
            font-size: 16px;
            color: #666
          }
        }
        .dropdown-wrapper{
          .dropdown{
            position: absolute;
            background: #fff;
            display: flex;
            flex-direction: column;
            transform: translateY(-25%);
          }
        }
      }
    }
  }
  .etapas{
    box-shadow: 4px 4px #d9d9d9;
    background-color: #f9f9f2;
    border-radius: 10px;
    padding: .5em 0;
    overflow: hidden;
    position: relative;
    >ul{
      width: 100%;
      height: 100%;
      padding: .5em;
      display: flex;
      align-items: center;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      justify-content: space-between;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
      @media screen and (min-width:768px) {
        display: flex;
        align-items: center;
        overflow-y: scroll;
        overflow-x: hidden;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 2em;
      }
      >li{
        user-select: none;
        &:hover{
          cursor: pointer;
        }
        scroll-snap-align: center;
        height: 100%;
        width: 5em;
        background-color: white;
        flex-shrink: 0;
        margin-right: .5em;
        @media screen and (min-width:768px) {
          height: 5em;
          width: 5em;
          margin-right: unset;
          margin-bottom: .5em;
        }
        background-color: rgba(19,119,242,.5);
        border-radius: 10px;  
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        >p{
          font-size: 16px;
          color: white;
          font-weight: bold;
          letter-spacing: 0.02em;
          word-wrap: break-word;
          width: 90%;
        }
        >button[type="button"]{
          position: absolute;
          top: 5%;
          right: 5%;
        }
      }
    }
    >span{
      position: absolute;
      bottom:0;
      background-color: #fff;
      padding: .5em;
      border-radius: 10px;
      >.v-icon{
        margin: 0 .5em;
      }
    }
  }
}
.modal-wrapper{
  position: absolute;
  top:0;
  right:0;
  left:0;
  bottom:0;
  display: grid;
  place-items: center;

  .modal{
    padding: 2em 1em;
    background-color: #fff;
    border-radius: 30px;
    height: auto;
    margin: 0 1em;
    .modal-header{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1em;;
    }
  }
}

.btn-voltar{
    padding: .75em 1em;
    line-height: 1.5;
    color: white;
    font-weight: bold;
    letter-spacing: 0.02em;
    background: #1377f2;
    border-radius: 10px;
    text-transform: capitalize;
  }

.todas-etapas{
  grid-template-rows: 1fr !important;
  grid-template-columns: 1fr !important;
  .etapas{
    grid-row: 1/2 !important;
    grid-column: 1/2 !important;
    >ul{
      flex-direction: column;
      li{
        height: 5em !important;
        width: 100% !important;
        margin-bottom: .5em; 
      }
    }    
  }
  @media screen and (min-width: 768px){
    grid-template-columns: .7fr .3fr !important;
    grid-template-rows: 1fr !important;
    grid-auto-flow: row;
    .etapas{
      grid-row: 1/2 !important;
      grid-column: 2/3 !important;
      >ul{
        flex-direction: row;
        flex-wrap: wrap;
        li{
          height: 8em !important;
          width: 8em !important;
          margin-bottom: .5em; 
        }
      } 
    }
  }
}

.btn-form{
    padding: .75em 1em;
    line-height: 1.5;
    color: white;
    font-weight: bold;
    letter-spacing: 0.02em;
    background: #1377f2;
    border-radius: 10px;
    text-transform: capitalize;
  }


</style>