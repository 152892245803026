<template>
  <div style="display: flex;flex-direction:column;align-items: flex-start;" @mousedown="(e) => onmousedown(e)">
    <label v-if="label" for="" class=""
                >{{label}}:</label>
    <section
      class="dropdown-wrapper"
      :id="'dropdown-wrapper' + id"
      :style="cssVars"
    >
    <div
        class="dropdown-container"
        @click="selecionarComponente"
        :style="cssVars"
        :name="name"
      >
        <span
          class="dropdown-placeholder"
          v-if="currItem === null && multiple === false"
        >
          <v-icon size="22" v-if="icon"> {{ icon }} </v-icon>{{ placeholder }}</span
        >
        <span
          class="dropdown-placeholder"
          v-if="setItems.size === 0 && multiple === true"
          ><v-icon size="22" v-if="icon"> {{ icon }} </v-icon>{{ placeholder }}</span
        >
        <span
          class="dropdown-data"
          v-if="currItem !== null && multiple === false"
        >
          <v-icon size="22" v-if="icon"> {{ icon }} </v-icon
          >{{ currItem.nome }}</span
        >
        <span
          class="dropdown-data"
          v-if="setItems.size > 0 && multiple === true"
        >
          <v-icon size="22" v-if="icon"> {{ icon }} </v-icon>
          <span class="item-multiple" v-for="(item, idx) of setItems" :key="idx"
            >{{ item.nome }} <span @click.stop="removerItemDoSet(item)">x</span>
          </span>
        </span>
      </div>
      <div
        v-if="isVisible"
        class="dropdown-popover"
        @blur="desfocoDropdown"
        tabindex="0"
      >
        <input
          autocomplete="off"
          type="text"
          v-model="search"
          placeholder="Pesquise aqui"
          :id="'search-input' + id"
          @input="manterItemsMarcados"
          v-if="ableToSearch"
        />
        <ul>
          <li
            v-for="item, idx in filteredOptions"
            :key="idx"
            :id="`${item.nome}-item`"
            :data-cy="`${item.nome.toLowerCase()}`"
            @click="selecionarItem(item)"
          >
            {{ item.nome }}
          </li>
        </ul>
      </div>
    </section>
      <div class="error-message" v-if="!hidedetails">{{error && error}}</div>
  </div>
</template>
<script>
const sleep = (time) => new Promise((acc) => setTimeout(acc, time));

export default {
  props: {
    options: Array,
    id: String,
    placeholder: String,
    label: String,
    sideEffect: Function,
    onmousedown: Function,
    multiple: Boolean,
    reset: Boolean,
    initialValue: String || Number,
    ableToSearch: Boolean,
    border: String,
    borderactive: String,
    borderradius: String,
    icon: String,
    disabled: Boolean,
    hidedetails: Boolean,
    error: String,
    name: String,
  },
  data() {
    return {
      search: "",
      isVisible: false,
      currItem: null,
      setItems: new Set(),
    };
  },
  computed: {
    filteredOptions() {
      const query = this.search.toLowerCase();
      if (this.search === "") {
        return this.options;
      }
      return this.options.filter((option) => {
        return Object.values(option).some((attr) =>
          String(attr).toLowerCase().includes(query)
        );
      });
    },
    cssVars() {
      return {
        /* variables you want to pass to css */
        "--border": this.border,
        "--border-active": this.borderactive,
        "--border-radius": this.borderradius,
      };
    },
  },
  watch: {
    //a variavel reset é obeservada e toda vez que for alterada
    //independente de true ou false, ela executa uma funcao para resetar os valores do componente
    reset: function () {
      console.log('reset')
        if (this.multiple) {
        this.setItems = new Set();
      }
      if (!this.multiple) {
        this.currItem = null;
      }
    },
    initialValue: function(){
      if (!this.multiple) {
        this.options.map((option) => {
          if (option.value == this.initialValue) {
            this.currItem = option;
           }
        });
      }
      if (this.multiple) {
        this.initialValue.map(item => {
          this.options.filter(el => el.value === item).map(op => {
            this.setItems.add(op)
            const set = this.setItems
            this.setItems = new Set(set)
          })
        })
      }
    },
           
  },
  methods: {
    async selecionarItem(item) {
      if (!this.multiple) {
          this.currItem = item;
          this.isVisible = false;
          this.$emit("input", item.value);
          this.sideEffect && await this.sideEffect();
        return null
      }
      if (this.multiple) {
        if (!this.setItems.has(item)) {
          this.setItems.add(item);
          let itemNode = document.getElementById(item.nome + "-item");
          itemNode.style.backgroundColor = "rgba(23, 123, 231, 0.9)";
          itemNode.style.transition = "0.4s ease-in-out";
          itemNode.style.fontWeight = "bold";
          itemNode.style.color = "white";
          let resultToEmit = new Array();
          for (let i of this.setItems) {
            resultToEmit.push(i.value);
          }
          this.$emit("input", resultToEmit);
        } else {
          this.setItems.delete(item);
          let itemNode = document.getElementById(item.nome + "-item");
          itemNode.style.backgroundColor = "unset";
          itemNode.style.transition = "0.4s ease-in-out";
          itemNode.style.fontWeight = "unset";
          itemNode.style.color = "unset";
          let resultToEmit = new Array();
          for (let i of this.setItems) {
            resultToEmit.push(i.value);
          }
          this.$emit("input", resultToEmit);
        }
        this.sideEffect && await this.sideEffect();
        return null
      }
    },
    alterarEstiloAtivo() {
      const id = "dropdown-wrapper" + this.id;
      this.isVisible
        ? document.getElementById(id).classList.add("active")
        : document.getElementById(id).classList.remove("active");
    },
    manterItemsMarcados() {
      if (this.multiple) {
          for (let item of this.setItems) {
          let itemNode = document.getElementById(item.nome + "-item");
          itemNode.style.backgroundColor = "rgba(23, 123, 231, 0.9)";
          itemNode.style.transition = "0.4s ease-in-out";
          itemNode.style.fontWeight = "bold";
          itemNode.style.color = "white";
        }
        
      }
      if (!this.multiple) {
        let otherNode = document.getElementById(this.currItem.nome + "-item");
        otherNode.style.backgroundColor = "rgba(23, 123, 231, 0.9)";
        otherNode.style.transition = "0.4s ease-in-out";
        otherNode.style.fontWeight = "bold";
        otherNode.style.color = "white";
      }
    },
    async selecionarComponente() {
        if (this.disabled) {
        return;
      }
      this.isVisible = !this.isVisible;
      this.alterarEstiloAtivo();
      await sleep(100);
      document.getElementById("search-input" + this.id)?.focus();
      await this.manterItemsMarcados();
    },
    removerItemDoSet(item) {
      if(!this.disabled){
        for (let i of this.setItems) {
        if (i.nome === item.nome) {
          this.setItems.delete(item);
          const set = this.setItems
          this.setItems = new Set(set)
          let resultToEmit = new Array();
          for (let k of this.setItems) {
            resultToEmit.push(k.value);
          }
          this.$emit("input", resultToEmit);          
        }
      }
      }
    },
    desfocoDropdown() {
      this.isVisible = false;
      this.alterarEstiloAtivo();
    },
  },
  mounted() {
    if(this.initialValue !== null){
      if (!this.multiple) {
        this.options.map((option) => {
          if (option.value == this.initialValue) {
            this.currItem = option;
           }
        });
      }
      if (this.multiple) {
        this.initialValue.map(item => {
          this.options.filter(el => el.value === item).map(op => {
            this.setItems.add(op)
            const set = this.setItems
            this.setItems = new Set(set)
          })
        })
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.error-message{
    line-height: 12px;
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    margin: 8px 0;
    padding: 0 12px;
    height: 14px;
}
.dropdown-wrapper {
  width: 100%;
  position: relative;
  //background: white;
  border-radius: .3em;
  border: 1px solid #9e9e9e;
  &:hover{
    border: 1px solid #212519;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    &:hover{
      border: 2px solid #212519;
      cursor: pointer;
    }     
  }

  .dropdown-container {
    height: 56px;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 300;
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    .dropdown-data{
      display: flex;
      align-items: center;
      font-weight: 300;
      color: #212121;
    }
    .dropdown-placeholder {
      display: flex;
      align-items: center;
      font-weight: 300;
      color: #666666;

      > i {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s all;
        margin: 0 0.7rem;
        color: rgba(15, 136, 242, 0.5);
      }
      &:target-within {
        border: 2px solid rgb(15, 136, 242);
        > i {
          color: rgb(15, 136, 242);
        }
      }
    }
    .dropdown-data {
      overflow-x: scroll;
      overscroll-behavior: none;
      scroll-behavior: smooth;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .dropdown-popover {
    position: absolute;
    border: 1px solid #9e9e9e;
    top: 46px;
    left: 0;
    right: 0;
    background-color: #fff;
    max-width: 100%;
    padding: 10px;
    z-index: 100;
    input {
      width: 100%;
      height: 50px;
      border: 1px solid #9e9e9e;
      font-size: 14px;
      outline: none;
      padding-left: 0.5rem;
    }
    ul {
      list-style: none;
      text-align: left;
      padding-left: 8px;
      max-height: 200px;
      overflow-y: scroll;
      overscroll-behavior: none;
      scroll-behavior: smooth;
      &::-webkit-scrollbar {
        display: none;
      }
      li {
        width: 100%;
        border-bottom: 1px solid #9e9e9e;
        padding: 10px;
        cursor: pointer;
        color: rgba(0,0,0,0.8);
        &:hover {
          background-color: rgba(23, 123, 231, 0.9);
          transition: 0.4s ease-in-out;
          font-weight: bold;
          color: white;
        }
      }
    }
  }
}
.active {
  border: 1px solid #9e9e9e;
  border-radius: .3em;
  transition: 0.4s ease-in-out;
}
.item-multiple {
  background-color: rgba(23, 123, 231, 0.9);
  font-size: 14px;
  padding: 0.3rem 0.5rem;
  border-radius: .3em;
  color: white;
  margin-right: 0.5rem;
  flex-shrink: 0;
}
  label {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.02rem;
  }
//css indesejado

.v-label .v-label--active{
    left: -22px !important
}
</style>
